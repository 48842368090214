<template>
  <div
      v-if="isMounted"
      class="connection"
      :class="{top: actionsIsVisible}"
      @mousedown="$emit('clickToConnection', 'mousedown'), pressMouseDown()"
  >
    <graph-editor-arrow
        :from="from"
        :to="to"
        :color="!actionsIsVisible ? defaultArrowColor : defaultActiveArrowColor"
        :with-marker="(scale > minScale)"
        :positionConnect="positionLine"
        :movement="{isMoveToConnects, nodeIsMoved}"
        :endNodeId="{endNodeId: connectionInfo.endNodeId}"
        :isLineCreated="isLineCreated"
        @click="eventClickArrow"
        @setTypeLine="setTypeLine"
        @lineConnections="$emit('lineConnections', $event)"
      />
    <ul
        class="connection__actions"
        :class="{visible: actionsIsVisible}"
        :style="{
          left: `${middlePosition[0]}px`,
          top: `${middlePosition[1]}px`,
        }"
    >
      <li
          class="connection__action"
          @click="deleteConnection"
      >
        <component :is="icons['iconDelete']" class="node__group-action-icon" />
      </li>
    </ul>
  </div>
</template>

<script>
import iconsVue from "../mixins/iconsVue.js";

import GraphEditorArrow from "../components/GraphEditorArrow.vue";

export default {
  mixins: [iconsVue],
  components: {GraphEditorArrow},
  props: {
    connectionInfo: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    minScale: {
      type: Number,
      required: true
    },
    moveConnectionLine: {
      type: Object
    },
    mouseUp: {
      type: Boolean
    },
    editorMode: {
      type: Boolean
    },
    isMoveToConnects: {
      type: Boolean
    },
    nodeIsMoved: {
      type: Boolean
    },
    selectedNodes: {
      type: Object
    }
  },
  watch: {
    moveConnectionLine(newPosition){
      if((!newPosition.x && !newPosition.y) || !this.isMove ) return this.positionLine

      this.positionLine.x += newPosition.x 
      this.positionLine.y += newPosition.y 
    },
    isMouseUp(mouseUp){
      if(mouseUp) {
        this.pressMouseUp()
        this.isLineCreated = false
      }
    },
    // when node moves, don't move connection on - x line
    to(newPos, oldPos){
      if(this.selectCurrentNodes) return

      if(newPos[0] !== oldPos[0]) this.positionLine.x -= (newPos[0] - oldPos[0]) * .5
      if(newPos[1] !== oldPos[1]) this.positionLine.y -= (newPos[1] - oldPos[1]) * .5
    },
    // when node moves, don't move connection on - x line
    from(newPos, oldPos){
      if(this.selectCurrentNodes) return

      if(newPos[0] !== oldPos[0]) this.positionLine.x -= (newPos[0] - oldPos[0]) * .5
      if(newPos[1] !== oldPos[1]) this.positionLine.y -= (newPos[1] - oldPos[1]) * .5
    }
  },
  data() {
    return {
      root: this.$parent.$parent.$parent,
      actionsIsVisible: false,
      defaultArrowColor: '#cccdd6',
      defaultActiveArrowColor: '#db7468',
      isMounted: false,

      positionLine: {x: 0, y: 0},
      typeLineConnect: '',
      isMove: false,
      isLineCreated: false,
    };
  },
  computed: {
    startElm() {
      return this.connectionInfo.startNodeComponent.$refs[`output-${this.connectionInfo.startNodeOutputName}`][0];
    },
    endElm() {
      return this.connectionInfo.endNodeComponent.$refs['input-main'];
    },
    from() {
      const outputBounds = this.startElm.getBoundingClientRect();
      const startNodeBounds = this.connectionInfo.startNodeComponent.$el.getBoundingClientRect();

      return [
        this.connectionInfo.startNodeComponent.node.position[0] + (outputBounds.left - startNodeBounds.left) /  this.scale + this.startElm.clientWidth/2,
        this.connectionInfo.startNodeComponent.node.position[1] + (outputBounds.top - startNodeBounds.top) /  this.scale + this.startElm.clientHeight/2,
        this.connectionInfo.startNodeId
      ];
    },
    to() {
      const inputBounds = this.endElm.getBoundingClientRect();
      const endNodeBounds = this.connectionInfo.endNodeComponent.$el.getBoundingClientRect();

      return [
        this.connectionInfo.endNodeComponent.node.position[0] + (inputBounds.left - endNodeBounds.left) / this.scale + this.endElm.clientWidth/2,
        this.connectionInfo.endNodeComponent.node.position[1] + (inputBounds.top - endNodeBounds.top) /  this.scale + this.endElm.clientHeight/2,
        this.connectionInfo.endNodeId
      ];
    },
    positionY(){
      if(this.typeLineConnect === 'straightLine') return 0;
      return this.positionLine.y
    },
    middlePosition: {
      caches: false,
      get(){
        return [
          (this.to[0] + this.from[0])/2 + this.positionLine.x,
          (this.to[1] + this.from[1])/2 + this.positionY,
        ];
      }
    },
    isMouseUp: {
      cashe: false,
      get(){
        return this.mouseUp
      }
    },
    selectCurrentNodes(){
      const {endNodeId, startNodeId} = this.connectionInfo
      const keys = Object.keys(this.selectedNodes)

      return keys.includes(endNodeId) && keys.includes(startNodeId)
    }
  },
  methods: {
    pressMouseDown(){
      this.isMove = true
    },
    pressMouseUp(){
      this.isMove = false
    },
    deleteConnection(){
      this.$emit('delete', this.connectionInfo.startNodeComponent.node.id, this.connectionInfo.startNodeOutputName, this.connectionInfo.endNodeComponent.node.id);
    },
    eventClickArrow(){
      if(this.editorMode) {
        this.actionsIsVisible
          ? this.hideActions()
          : this.showActions()
      }
    },
    showActions() {
      this.actionsIsVisible = true;
    },
    hideActions() {
      this.actionsIsVisible = false;
    },
    setTypeLine(typeLine){
      this.typeLineConnect = typeLine
    },
  },
  mounted(){
    this.$nextTick(function () {
      this.isLineCreated = true
      if(!this.connectionInfo?.lineOffset) return 

      this.positionLine = this.connectionInfo.lineOffset
      this.typeLineConnect = this.connectionInfo.typeLine
    })
  },
  beforeMount() {
    if(!this.startElm || !this.endElm) {
      console.error(`
      Cant mount connection\n
      Start Node Id: ${this.connectionInfo.startNode.id}\n
      Output: ${this.connectionInfo.startNodeOutputName}\n
      End Node Id: ${this.connectionInfo.endNode.id}\n\t
      Start Node Component: ${this.startElm}\n
      End Node Component: ${this.endElm}
      `);
      return;
    }

    this.isMounted = true;
  }
}

</script>

<style scoped lang="scss">

.connection {
  position: absolute;
  z-index: 1;

  &.top {
    z-index: 2;
  }
}

.connection__actions {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  list-style: none;
  transition: opacity .2s, visibility .2s;
  will-change: opacity;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.connection__action {
  width: 28px;
  height: 28px;
  border: 2px solid #7d838f;
  background: #fff;
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: #db7468;

    & svg {
      fill: #db7468;
    }
  }

  & svg {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: #7d838f;
  }
}

</style>